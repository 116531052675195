import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PageApi } from 'API/CMS/page'
import { IPage, Status } from 'interfaces'

const initialState: {
	list: IPage[]
	status: Status
	selected?: IPage
} = {
	list: [],
	status: 'init',
}

export const getPages = createAsyncThunk('Page/getPages', async () => {
	return await PageApi.getAll()
})
export const getPage = createAsyncThunk('Page/getPage', async (id: number) => {
	return await PageApi.getOne(id)
})

const PageSlice = createSlice({
	name: 'Page',
	initialState,
	reducers: {
		setSelected(state, { payload }: PayloadAction<typeof initialState.selected>) {
			state.selected = payload
		},
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPages.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getPages.fulfilled, (state, { payload }) => {
			if (payload) state.list = payload as any
			state.status = 'fulfilled'
		})
		builder.addCase(getPage.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload as any)
			} else {
				state.list.push(payload as any)
			}
		})
	},
})

export const PageAction = PageSlice.actions

export const PageReducer = PageSlice.reducer
