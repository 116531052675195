import { IWeb } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const WebApi = {
	async getAll() {
		const {
			data: { webs },
		} = await axiosClient.get<{ webs: IWeb[] }>('/api/cms/web')
		return webs
	},
	async getOne(id: number | string) {
		const {
			data: { web },
		} = await axiosClient.get<{ web: IWeb }>(`/api/cms/web/${id}`)
		return web
	},
	async create(body: {
		name: string
		ogURL: string
		ogImage: string
		ogDescription: string
		favicon: string
		groups: number[]
	}) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>(`/api/cms/web/`, body)
		return data
	},
	async update({
		id,
		favicon,
		ogDescription,
		ogImage,
		ogURL,
		groups,
	}: {
		id: number | string
		ogURL?: string
		favicon?: string
		ogImage?: string
		ogDescription?: string
		groups?: number[]
	}) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>(`/api/cms/web/${id}`, {
			favicon,
			ogDescription,
			ogImage,
			ogURL,
			groups,
		})
		return data
	},
	async delete(id: number | string) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string }>(`/api/cms/web/${id}`)
		return data
	},
}
