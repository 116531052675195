// import { CSSProperties } from 'react'

export type Status = 'init' | 'pending' | 'fulfilled' | 'rejected'
export interface GlobalMessage {
	id: number
	message: string
	type: 'success' | 'info' | 'warning' | 'error'
	time?: number
}
export type SocketChanel =
	| 'current-path'
	| 'config'
	| 'group'
	| 'form'
	| 'account'
	| 'domain'
	| 'cms-theme'
	| 'cms-template'
	| 'cms-page'
	| 'cms-web'
	| 'mail-group'
	| 'mail-history'
	| 'author'
	| 'workflow'
	| 'local'
	| 'answer'
	| 'system-notice'
export type SocketAction = 'created' | 'updated' | 'deleted' | 'async'
export const enum PERMISSION {
	CMS = 0,
	FORM = 1,
	MAIL = 2,
	MAIL_ADDRESS = 3,
	ACCOUNT = 4,
	FORM_ANSWER = 5,
	CONFIG = 6,
}

export interface TokenInfo {
	id: number
	name: string
	email: string
	type: 'Default' | 'MSAL'
	groupId: number
	groupName: string
	permissions: number[]
	workflow?: {
		email: string
		admin: boolean
	}
	webs: number[]
}

export interface IDomain {
	id: number
	name: string
	displayName: string
	clientId: string
	tenantId: string
}

export interface IAccount {
	id: number
	type: 'Default' | 'MSAL'
	groupId: number
	name: string
	email: string
	status: 'active' | 'disabled' | 'deleted'
	updatedAt: string
	createdAt: string
}
export interface IGroup {
	id: number
	name: string
	permission: number[]
	description: string
	status: 'active' | 'deleted'
	updatedAt: string
	createdAt: string
	accounts: IAccount[]
}

export interface ITheme {
	id: number
	name: string
	head: string
	style: string
	script: string
	body: string
	updatedAt: string
	createdAt: string
}
export interface IWeb {
	id: number
	name: string
	favicon: string
	ogURL: string
	ogImage: string
	ogDescription: string
	updatedAt: string
	createdAt: string
	groups: number[]
}
type PageStatus = '作成済' | '更新中' | '申請中' | '公開待ち' | '公開済' | '非公待ち' | '非公開'
export interface IPage {
	id: number
	webId: number
	themeId: number
	url: string
	title: string
	description: string
	dateOfPage?: string
	statusOfPage?: string
	owner: number
	subOwner: number[] // '[1,2,3,4]'
	body: { [key: string]: string }
	style: string
	script: string
	formStyle: string
	formScript: string
	uuid: string // ver 管理ため
	ver: number
	active: boolean // 使うかどうか(削除したらfalseになる)
	status: PageStatus
	web: IWeb
	theme: ITheme
	updatedAt: string
	createdAt: string
}

export interface ITemplate {
	id: number
	webId: number
	themeId: number
	name: string
	owner: number
	subOwner: number[] // '[1,2,3,4]'
	body: { [key: string]: string }
	style: string
	script: string
	formStyle: string
	formScript: string
	uuid: string // ver 管理ため
	ver: number
	active: boolean
	updatedAt: string
	createdAt: string
	theme: ITheme
}

export interface IFileLocal {
	id: number
	name: string
	type: 'imgs' | 'docs'
	path: string
	size: number
	ext: string
	desc: string
	pageUses: string[]
}
export interface ValidateError {
	type: string
	lastLine: number
	lastColumn: number
	firstColumn: number
	message: string
	extract: string
	hiliteStart: number
	hiliteLength: number
}

export interface IForm {
	id: number
	webId: number
	name: string
	startDate?: string
	endDate?: string
	limitAnswer?: number
	useNotice: string[]
	allowJs?: boolean
	isMailSend?: boolean
	isMailWithContent?: boolean
	successContent?: string
	errorContent?: string
	status: string
	owner: number
	subOwner: number[]
	items: any[]
}
export interface FormItemProps {
	id: string
	name: string
	value: any
	placeholder?: string
	type: 'text' | 'email' | 'date' | 'file' | 'checkbox' | 'radio' | 'select' | 'text-area' | 'code' | 'richtext'
	required: boolean
	minlength?: number
	maxlength?: number
	isUseMailSend?: boolean
	min?: string
	max?: string
	rows?: number
	accept?: string // "image/*,.docx,.doc,.xlsx,.xls,.txt,.csv,.pdf,.zip"
	textPattern?: 'en' | 'num' | 'en-num' | 'ja' | 'en-num-sym' | 'ja-kana' | 'ja-hira' | 'full'
	textHelper?: string
	textHelperBottom?: boolean
	options: { id: string; text: string }[]
	error?: string
}

export interface IFormItem {
	format?: '短文' | '日付' | '選択肢' | '文章（回答なし）' | '長文' | 'メールアドレス' | '一覧' | 'ファイル'
	display: string
	placeholder?: string
	minlength?: string
	maxlength?: string
	rows?: string
	textType?: 'en' | 'num' | 'en-num' | 'ja' | 'en-num-sym'
	required?: boolean
	textHelper?: string
	textHelperBottom?: boolean
	options: {
		id: string
		text: string
	}[]
	multiSelect?: boolean
	useTime?: boolean
	name: string
	branch?: boolean
	branchItems?: {
		option: string
		list: IFormItem[]
	}[]
	fileType: string
}
export interface IMailMember {
	id: number
	groupId: number
	email: string
}
export interface IMailing {
	id: number
	name: string
	type: string
	allowGroup: number[]
	mailMembers: IMailMember[]
}
export interface ISendMailHistory {
	id: number
	subject: string
	body: string
	sender: IAccount
	organization: Omit<IGroup, 'accounts'>
	mailing: Omit<IMailing, 'mailMembers'>
	schedule?: ISchedule
	status: 'waiting' | 'canceled' | 'success' | 'failed'
	createdAt: string
	updatedAt: string
}
export interface IWorkflow {
	id: number
	sender: number // Người gửi yêu cầu
	authorizer: number[] // Danh sách những người xác nhận
	approver: number // Người xác nhận
	pageId: number // ID trang cần xác nhận
	other: string // Nếu có vấn đề gì thì ghi
	hopeTime: number
	type: 'public' | 'unPublic'
	status: 'created' | 'accepted' | 'rejected' | 'remanded'
	createdAt: string
	updatedAt: string
	page: IPage
	senderUser: IAccount
	approverUser: IAccount
	schedule?: ISchedule
}

export interface IWebAuthor {
	id: number
	email: string
	admin: boolean
	webIds: number[]
	accounts: IAccount[]
}
export interface IWebAuthorUser {
	id: number
	webAuthorId: number
	accountId: number
}

export interface IFormAnswer {
	formId: number
	formName: string
	webName: string
	startDate?: string
	endDate?: string
	answers: {
		id: number
		[key: string]: any
		createdAt: string
		updatedAt: string
		downloaded: boolean
	}[]
}
export interface ISchedule {
	id: number
	taskType: 'public' | 'unPublic' | 'send-mail'
	dateTime: number
	workflowId?: number
	mailId?: number
	mailData?: {
		subject: string
		mailing: string[]
		html: string
	}
	status: 'created' | 'running' | 'success' | 'failed' | 'canceled'
}

export interface INotice {
	id: number
	type: 'エラー' | '成功' | '警告' | 'お知らせ' | '緊急'
	groupId: number[]
	important: boolean
	content: string
	createdAt: string
	updatedAt: string
}
