import NotFound from 'NotFound'
import MaterialProvider from 'components/Layout/theme'
import { PERMISSION } from 'interfaces'
import { Fragment, Suspense, lazy, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useAuth } from 'redux/hooks'
import { getDomains, systemConfig } from 'redux/slices/UI'
type PAGE = {
	path: string
	permission?: PERMISSION
	element: React.LazyExoticComponent<() => JSX.Element | null>
}
const pages: PAGE[] = [
	{
		path: '/overview',
		element: lazy(() => import('pages/Overview')),
	},
	{
		path: '/group',
		permission: PERMISSION.ACCOUNT,
		element: lazy(() => import('pages/Group')),
	},
	{
		path: '/group/create',
		permission: PERMISSION.ACCOUNT,
		element: lazy(() => import('pages/Group/Create')),
	},
	{
		path: '/group/:id',
		permission: PERMISSION.ACCOUNT,
		element: lazy(() => import('pages/Group/Detail')),
	},
	{
		path: '/cms',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS')),
	},
	{
		path: '/cms/theme',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Theme')),
	},
	{
		path: '/cms/theme/:id',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Theme/Update')),
	},
	{
		path: '/cms/template',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Template')),
	},
	{
		path: '/cms/template/:id',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Template/Update')),
	},
	{
		path: '/cms/template/create',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Template/Create')),
	},
	{
		path: '/cms/files',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/FileLocal')),
	},
	{
		path: '/cms/website',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Web')),
	},
	{
		path: '/cms/website/create',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Web/Create')),
	},
	{
		path: '/cms/:webId/page',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Web/Page')),
	},
	{
		path: '/cms/:webId/page/create',
		element: lazy(() => import('pages/CMS/Web/Page/Create')),
	},
	{
		path: '/cms/:webId/page/:id',
		permission: PERMISSION.CMS,
		element: lazy(() => import('pages/CMS/Web/Page/Update')),
	},
	// form
	{
		path: '/form',
		permission: PERMISSION.FORM,
		element: lazy(() => import('pages/Form')),
	},
	{
		path: '/form/:id',
		permission: PERMISSION.FORM,
		element: lazy(() => import('pages/Form/Update')),
	},
	{
		path: '/form/create',
		permission: PERMISSION.FORM,
		element: lazy(() => import('pages/Form/Create')),
	},
	// mailing
	{
		path: '/mailing',
		permission: PERMISSION.MAIL_ADDRESS,
		element: lazy(() => import('pages/Mailing')),
	},
	{
		path: '/mailing/create',
		permission: PERMISSION.MAIL_ADDRESS,
		element: lazy(() => import('pages/Mailing/Create')),
	},
	{
		path: '/mailing/:id',
		permission: PERMISSION.MAIL_ADDRESS,
		element: lazy(() => import('pages/Mailing/Detail')),
	},
	// Send Mail
	{
		path: '/mail',
		permission: PERMISSION.MAIL,
		element: lazy(() => import('pages/Mail')),
	},
	// setting
	{
		path: '/setting',
		permission: PERMISSION.CONFIG,
		element: lazy(() => import('pages/Setting')),
	},
	// author
	{
		path: '/author',
		permission: PERMISSION.CONFIG,
		element: lazy(() => import('pages/Author')),
	},
	{
		path: '/author/:id',
		permission: PERMISSION.CONFIG,
		element: lazy(() => import('pages/Author/Update')),
	},
	// Workflow
	{
		path: '/workflow',
		element: lazy(() => import('pages/Workflow')),
	},
	// Notice
	{
		path: '/notice',
		element: lazy(() => import('pages/Notice')),
	},
	// form answer
	{
		path: '/form-answer',
		permission: PERMISSION.FORM_ANSWER,
		element: lazy(() => import('pages/FormAnswer')),
	},
	{
		path: '/form-answer/:id',
		permission: PERMISSION.FORM_ANSWER,
		element: lazy(() => import('pages/FormAnswer/Detail')),
	},
	// change pass
	{
		path: '/pw-update',
		element: lazy(() => import('pages/ChangePassword')),
	},
]
const dialogs: PAGE[] = [
	{
		path: '/cms/theme/create',
		element: lazy(() => import('pages/CMS/Theme/Create')),
	},
	{
		path: '/cms/template/:id/delete',
		element: lazy(() => import('pages/CMS/Template/Delete')),
	},
	{
		path: '/cms/theme/:id/delete',
		element: lazy(() => import('pages/CMS/Theme/Delete')),
	},
	{
		path: '/cms/website/:id/delete',
		element: lazy(() => import('pages/CMS/Web/Delete')),
	},
	{
		path: '/cms/:webId/page/:id/delete',
		element: lazy(() => import('pages/CMS/Web/Page/Delete')),
	},
	{
		path: '/form/:id/delete',
		element: lazy(() => import('pages/Form/Delete')),
	},
	{
		path: '/group/:id/account/create',
		element: lazy(() => import('pages/Group/Account/Create')),
	},
	{
		path: '/group/:gId/:id/change-group',
		element: lazy(() => import('pages/Group/Account/ChangeGroup')),
	},
	{
		path: '/group/:gId/:id/reset',
		element: lazy(() => import('pages/Group/Account/Reset')),
	},
	{
		path: '/mailing/:id/delete',
		element: lazy(() => import('pages/Mailing/Delete')),
	},
	{
		path: '/mailing/:id/assign',
		element: lazy(() => import('pages/Mailing/AssignGroup')),
	},
	{
		path: '/mailing/:id/member',
		element: lazy(() => import('pages/Mailing/AddMail')),
	},
	{
		path: '/mailing/:id/member/remove/:mId',
		element: lazy(() => import('pages/Mailing/RemoveMail')),
	},
	{
		path: '/setting/domain/:id/delete',
		element: lazy(() => import('pages/Setting/DeleteDomain')),
	},
	{
		path: '/author/create',
		element: lazy(() => import('pages/Author/Create')),
	},
	{
		path: '/author/:id/delete',
		element: lazy(() => import('pages/Author/Delete')),
	},
	{
		path: '/workflow/:id/reject',
		element: lazy(() => import('pages/Workflow/Reject')),
	},
	{
		path: '/workflow/:id/approve',
		element: lazy(() => import('pages/Workflow/Approve')),
	},
	{
		path: '/mail/:id/cancel',
		element: lazy(() => import('pages/Mail/Cancel')),
	},
]
const AuthPages: PAGE[] = [
	{
		path: '/',
		element: lazy(() => import('pages/Auth/Login')),
	},
	{
		path: '/login/update-password',
		element: lazy(() => import('pages/Auth/UpdatePassword')),
	},
	{
		path: '/login/verify-otp',
		element: lazy(() => import('pages/Auth/VerifyOtp')),
	},
	{
		path: '/login/verify-token',
		element: lazy(() => import('pages/Auth/VerifyToken')),
	},
	{
		path: '/login/guide-otp',
		element: lazy(() => import('pages/Auth/GuideOtp')),
	},
]
function App() {
	const tokenInfo = useAppSelector((s) => s.UI.tokenInfo)
	const loginStatus = useAppSelector((s) => s.UI.loginStatus)
	const location = useLocation()
	const state = location.state as {
		location?: Location
	}
	const { getAuth } = useAuth()
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(getDomains())
		dispatch(systemConfig())
		getAuth()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HelmetProvider>
			<MaterialProvider>
				{state?.location && (
					<Routes location={location}>
						{dialogs
							.filter(
								(f) =>
									f.permission === undefined ||
									(tokenInfo && (tokenInfo.groupId === 1 || tokenInfo.permissions[f.permission] > 0))
							)
							.map((page) => {
								return (
									<Route
										key={page.path}
										path={page.path}
										element={
											loginStatus === 'initial' ? (
												<Loading />
											) : tokenInfo ? (
												<Suspense fallback={<Loading />}>
													<page.element />
												</Suspense>
											) : (
												<Navigate to="/" />
											)
										}
									/>
								)
							})}
					</Routes>
				)}
				<Routes location={state?.location || location}>
					{pages.map((page, i) => {
						return (
							<Fragment key={page.path}>
								<Route
									element={
										loginStatus === 'initial' ? (
											<Loading />
										) : tokenInfo ? (
											page.permission === undefined || tokenInfo.permissions[page.permission] > 0 ? (
												<Suspense fallback={<Loading />}>
													<page.element />
												</Suspense>
											) : (
												<NotFound />
											)
										) : (
											<Navigate to="/" />
										)
									}
									path={page.path}
								/>
							</Fragment>
						)
					})}
					{AuthPages.map((page, i) => {
						return (
							<Fragment key={page.path}>
								<Route
									element={
										loginStatus === 'initial' ? (
											<Loading />
										) : tokenInfo ? (
											<Navigate to="/overview" />
										) : (
											<Suspense fallback={<Loading />}>
												<page.element />
											</Suspense>
										)
									}
									path={page.path}
								/>
							</Fragment>
						)
					})}
					<Route element={<NotFound />} path="*"></Route>
				</Routes>
			</MaterialProvider>
		</HelmetProvider>
	)
}

export default App

export function Loading() {
	return (
		<div className="loading">
			<svg width="100px" height="98px" viewBox="0 0 190 188" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<g stroke="none" fill="none">
					<g transform="translate(-213.000000, -241.000000)" fillRule="nonzero" fill="#44AFF1">
						<g transform="translate(213.000000, 241.000000)">
							<path
								className="path-1"
								d="M131.494112,174.487877 C130.645264,172.751596 128.561726,172.018499 126.825445,172.867348 C116.832182,177.690351 106.067239,180.159729 94.8778708,180.159729 C77.9394831,180.159729 61.6184398,174.333541 48.5384547,163.722933 C53.245706,158.745594 56.139508,152.031973 56.139508,144.662424 C56.139508,129.344565 43.6768673,116.881925 28.3590087,116.881925 C13.0411501,116.881925 0.578509407,129.344565 0.578509407,144.662424 C0.578509407,159.980283 13.0411501,172.442923 28.3590087,172.442923 C33.7221884,172.442923 38.7766959,170.899562 43.0209389,168.237264 C57.5285329,180.468401 75.8173616,187.143437 94.8778708,187.143437 C107.147591,187.143437 118.91572,184.442556 129.873583,179.156544 C131.609864,178.307695 132.342961,176.224158 131.494112,174.487877 Z M7.56221825,144.62384 C7.56221825,133.164384 16.8995527,123.82705 28.3590087,123.82705 C39.8184646,123.82705 49.1557991,133.164384 49.1557991,144.62384 C49.1557991,156.083296 39.8184646,165.42063 28.3590087,165.42063 C16.8995527,165.42063 7.56221825,156.083296 7.56221825,144.62384 Z"
							></path>
							<path
								className="path-2"
								d="M14.5073431,102.875923 C14.430175,104.805124 15.9349521,106.425653 17.9027374,106.502822 L18.0184895,106.502822 C19.9091068,106.502822 21.4524679,104.998045 21.5296359,103.107427 C22.3784845,78.0663938 35.6899738,55.4175701 57.1041086,42.4919211 C60.460919,40.4855517 63.9720654,38.7106865 67.5603799,37.2444935 C71.3030305,48.1637731 81.6821337,56.0734986 93.8361021,56.0734986 C109.153961,56.0734986 121.616601,43.6108579 121.616601,28.2929993 C121.616601,12.9751406 109.153961,0.5125 93.8361021,0.5125 C78.5182435,0.5125 66.0556028,12.9751406 66.0556028,28.2929993 C66.0556028,28.9875118 66.0941869,29.6434402 66.1327709,30.2993687 C61.7727759,31.9970658 57.4899489,34.0806033 53.4772101,36.511397 C30.0181218,50.6331508 15.4333597,75.44268 14.5073431,102.875923 Z M93.8746861,7.49620885 C105.334142,7.49620885 114.671477,16.8335433 114.671477,28.2929993 C114.671477,39.7524552 105.334142,49.0512057 93.8746861,49.0512057 C82.4152302,49.0512057 73.0778957,39.7138712 73.0778957,28.2544152 C73.0778957,16.7949593 82.3766462,7.49620885 93.8746861,7.49620885 Z"
							></path>
							<path
								className="path-3"
								d="M161.859741,116.843341 C146.541883,116.843341 134.079242,129.305981 134.079242,144.62384 C134.079242,159.941699 146.541883,172.404339 161.859741,172.404339 C177.1776,172.404339 189.640241,159.941699 189.640241,144.62384 C189.640241,133.858897 183.466796,124.482978 174.476718,119.891479 C175.248399,115.338564 175.672823,110.70848 175.672823,106.078397 C175.672823,78.6837382 161.936909,53.4112007 138.940829,38.4405983 C137.3203,37.3988296 135.159595,37.8618379 134.117826,39.482367 C133.076057,41.1028962 133.539066,43.2636017 135.159595,44.3053704 C156.187889,57.9641159 168.727698,81.0373639 168.727698,106.078397 C168.727698,109.898216 168.419026,113.718035 167.840265,117.499269 C165.911064,117.074845 163.904695,116.843341 161.859741,116.843341 Z M182.656532,144.62384 C182.656532,156.083296 173.319197,165.42063 161.859741,165.42063 C150.400285,165.42063 141.062951,156.083296 141.062951,144.62384 C141.062951,133.164384 150.400285,123.82705 161.859741,123.82705 C173.319197,123.82705 182.656532,133.164384 182.656532,144.62384 Z"
							></path>
						</g>
					</g>
				</g>
			</svg>
		</div>
	)
}
