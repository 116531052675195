import { ITemplate } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const TemplateApi = {
	async getAll() {
		const {
			data: { templates },
		} = await axiosClient.get<{ templates: ITemplate[] }>('/api/cms/template')
		return templates
	},
	async getOne(id: number | string) {
		const {
			data: { template },
		} = await axiosClient.get<{ template: ITemplate }>(`/api/cms/template/${id}`)
		return template
	},
	async create(body: { themeId: number; name: string; body: { [key: string]: string } , webId?:number}) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>(`/api/cms/template/`, body)
		return data
	},
	async update({
		id,
		name,
		body,
		// subOwner,
		style,
		script,
		formScript,
		formStyle,
	}: {
		id: number | string
		name?: string
		body?: { [key: string]: string }
		// subOwner: number[]
		style?: string
		script?: string
		formStyle?: string
		formScript?: string
	}) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>(`/api/cms/template/${id}`, {
			name,
			body,
			// subOwner,
			style,
			script,
			formScript,
			formStyle,
		})
		return data
	},
	async active(id: number | string) {
		const { data } = await axiosClient.patch<{ error: boolean; message: string }>(`/api/cms/template/${id}/active`)
		return data
	},
	async delete(id: number | string) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string }>(`/api/cms/template/${id}`)
		return data
	},
}
