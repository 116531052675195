import axiosClient from 'utils/axiosClient'

export const GroupApi = {
	async getAll() {
		const {
			data: { groups },
		} = await axiosClient.get('/api/group')
		return groups
	},
	async getOne(id: number | string) {
		const {
			data: { group },
		} = await axiosClient.get(`/api/group/${id}`)
		return group
	},
	async create({ name, permission, description }: { name: string; permission: string; description?: string }) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>('/api/group/', {
			name,
			permission,
			description,
		})
		return data
	},
	async delete(id: number) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>('/api/group/' + id)
		return { error, message }
	},
	async update({
		id,
		name,
		permission,
		description,
	}: {
		id: number | string
		name?: string
		permission?: number[]
		description?: string
	}) {
		const {
			data: { error, message },
		} = await axiosClient.put<{ error: boolean; message: string }>('/api/group/' + id, {
			name,
			permission,
			description,
		})
		return { error, message }
	},
}
