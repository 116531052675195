import { createTheme, ThemeProvider } from '@mui/material'
import { jaJP } from '@mui/material/locale'
import { useMemo } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import jaLocale from 'date-fns/locale/ja'
import Layout from '.'

interface Props {
	children: any
}

export default function MaterialTheme({ children }: Props) {
	const theme = useMemo(() => {
		return createTheme(
			{
				typography: {
					fontFamily: [
						'Noto Sans JP',
						'Noto Serif JP',
						'Zen Old Mincho',
						'-apple-system',
						'BlinkMacSystemFont',
						'"Segoe UI"',
						'Roboto',
						'"Helvetica Neue"',
						'Arial',
						'sans-serif',
						'"Apple Color Emoji"',
						'"Segoe UI Emoji"',
						'"Segoe UI Symbol"',
					].join(','),
				},
				palette: {
					primary: {
						main: '#333',
					},
					secondary: {
						main: '#7EC4E9',
						contrastText: '#fff',
					},
					error: {
						main: '#e53935',
					},
					info: {
						main: '#1976d2',
					},
					warning: {
						main: '#f57c00',
						contrastText: '#fff',
					},
					success: {
						main: '#388e3c',
					},
				},
				mixins: {
					toolbar: {
						height: 50,
					},
				},
				components: {
					MuiFormLabel: {
						styleOverrides: {
							asterisk: {
								color: '#db3131',
								'&$error': {
									color: '#db3131',
								},
							},
						},
					},
				},
			},
			jaJP
		)
	}, [])
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale}>
				<Layout>{children}</Layout>
			</LocalizationProvider>
		</ThemeProvider>
	)
}
