import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkflowAPI } from 'API/workflow'
import { IWorkflow, Status } from 'interfaces'

const initialState: {
	list: IWorkflow[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getWorkflows = createAsyncThunk('Workflow/getWorkflows', async () => {
	const data = await WorkflowAPI.getAll()
	return data
})
export const getWorkflow = createAsyncThunk('Workflow/getWorkflow', async (id: number) => {
	return await WorkflowAPI.getOne(id)
})

const WorkflowSlice = createSlice({
	name: 'Workflow',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getWorkflows.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getWorkflows.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getWorkflows.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload
			}
			state.status = 'fulfilled'
		})
		builder.addCase(getWorkflow.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.push(payload)
			}
		})
	},
})

export const WorkflowAction = WorkflowSlice.actions

export const WorkflowReducer = WorkflowSlice.reducer
