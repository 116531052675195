import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector, useAuth } from 'redux/hooks'
import Alerts from './Alert'
import LeftMenu from './LeftMenu'
import SocketClient from './SocketClient'
import Notice from './Notice'
import { Loading } from 'App'

interface Props {
	children: any
}
export default function Layout({ children }: Props) {
	const tokenInfo = useAppSelector((s) => s.UI.tokenInfo)
	const config = useAppSelector((s) => s.UI.config)
	const s1 = useAppSelector((s) => s.Form.status)
	const s2 = useAppSelector((s) => s.CMS.Page.status)
	const s3 = useAppSelector((s) => s.CMS.Theme.status)
	const s4 = useAppSelector((s) => s.CMS.Web.status)
	const s5 = useAppSelector((s) => s.CMS.Template.status)
	const s6 = useAppSelector((s) => s.FileLocal.status)
	const s7 = useAppSelector((s) => s.FormAnswer.status)
	const s8 = useAppSelector((s) => s.Group.status)
	const s9 = useAppSelector((s) => s.MailHistory.status)
	const s10 = useAppSelector((s) => s.Mailing.status)
	const s11 = useAppSelector((s) => s.Notice.status)
	const s12 = useAppSelector((s) => s.WebAuthor.status)
	const s13 = useAppSelector((s) => s.Workflow.status)
	const loading = [s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13].findIndex((f) => f === 'pending') > 0
	const { signout } = useAuth()

	useEffect(() => {
		const setAuthTime = () => {
			if (tokenInfo) {
				const beforeTime = Number(localStorage.getItem('auth_time') || '0')
				const remainTime = beforeTime + Number(config.signinAge || '3600000') - new Date().getTime()
				if (remainTime > 0) {
					localStorage.setItem('auth_time', new Date().getTime().toString())
				} else {
					signout()
				}
			}
		}
		window.addEventListener('click', setAuthTime)
		return () => {
			window.removeEventListener('click', setAuthTime)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenInfo])

	return (
		<Stack>
			{tokenInfo && <SocketClient />}
			<Alerts />
			<AppBar position="static">
				<Toolbar>
					<Stack direction="row" alignItems="center" gap={2} height="100%" width="100%">
						<Typography
							sx={{
								color: '#fff',
								fontSize: '145%',
								fontWeight: 600,
								userSelect: 'none',
								mt: -1,
							}}
						>
							消費者庁管理画面
						</Typography>
						<Stack flex={1} justifyContent="flex-end">
							{tokenInfo && (
								<Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
									{tokenInfo?.type === 'Default' && (
										<Link to={'/pw-update'}>
											<Button
												sx={{
													color: '#fff',
													'&:hover': {
														color: '#f60',
													},
												}}
												size="small"
												color="inherit"
												variant="outlined"
											>
												パスワード変更
											</Button>
										</Link>
									)}
									<Stack>
										<Typography variant="body2">
											[{tokenInfo.groupName}]{tokenInfo.name}
										</Typography>
										<Typography variant="caption">{tokenInfo.email}</Typography>
									</Stack>
									{!!tokenInfo && <Notice />}
									<Button
										color="warning"
										variant="outlined"
										onClick={async (e) => {
											e.stopPropagation()
											e.preventDefault()
											await signout()
										}}
									>
										Signout
									</Button>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
			<Stack
				sx={{
					position: 'relative',
					width: '100%',
				}}
				direction="row"
			>
				{tokenInfo && <LeftMenu />}
				<Box
					flex={1}
					sx={{
						position: 'relative',
						p: 1,
						height: (theme) => `calc(100vh - ${theme.mixins.toolbar.height}px)`,
					}}
					className="scroll-bar"
				>
					{loading ? <Loading /> : children}
				</Box>
			</Stack>
		</Stack>
	)
}
