import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { noticeAPI } from 'API/notice'
import { INotice, Status } from 'interfaces'

const initialState: {
	list: INotice[]
	status: Status
	unread: {
		key: string
		value: INotice[]
	}
} = {
	list: [],
	status: 'init',
	unread: {
		key: '',
		value: [],
	},
}

export const getNotices = createAsyncThunk('Notice/getNotices', async (max?: number) => {
	const data = await noticeAPI.getAll(max)
	return data
})
export const getNotice = createAsyncThunk('Notice/getNotice', async (id: number) => {
	return await noticeAPI.getOne(id)
})

const NoticeSlice = createSlice({
	name: 'Notice',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
		setUnread(
			state,
			{
				payload,
			}: PayloadAction<{
				key: string
				value: INotice[]
			}>
		) {
			state.unread = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getNotices.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getNotices.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getNotices.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload
			}
			state.status = 'fulfilled'
		})
		builder.addCase(getNotice.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.unshift(payload)
			}
		})
	},
})

export const NoticeAction = NoticeSlice.actions

export const NoticeReducer = NoticeSlice.reducer
