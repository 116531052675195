import { Divider, Stack, Typography } from '@mui/material'
import { PERMISSION } from 'interfaces'
import { useEffect, useMemo } from 'react'
import {
	// AiOutlineDeploymentUnit,
	AiOutlineFile,
	AiOutlineMail,
	AiOutlineNotification,
	AiOutlineSetting,
	AiOutlineUsergroupAdd,
} from 'react-icons/ai'
import { BiMailSend } from 'react-icons/bi'
import { CgTemplate } from 'react-icons/cg'
import { FaFileContract, FaNetworkWired, FaPager, FaWpforms } from 'react-icons/fa6'
import { ImInsertTemplate } from 'react-icons/im'
import { MdDashboard, MdOutlinePostAdd, MdQuestionAnswer } from 'react-icons/md'
import { RiMailAddLine } from 'react-icons/ri'
import { VscOrganization, VscRootFolderOpened, VscWorkspaceTrusted } from 'react-icons/vsc'
import { NavLink, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getPages } from 'redux/slices/CMS/Page'
import { getTemplates } from 'redux/slices/CMS/Template'
import { getThemes } from 'redux/slices/CMS/Theme'
import { getWebs } from 'redux/slices/CMS/Web'
import { getFileLocals } from 'redux/slices/FileLocal'
import { getForms } from 'redux/slices/Form'
import { getGroups } from 'redux/slices/Group'
import { getCommonCss } from 'redux/slices/UI'
import { getWebAuthors } from 'redux/slices/WebAuthor'
import { getWorkflows } from 'redux/slices/Workflow'

type CATEGORY = {
	text: string
	href: string
	requirePermission?: PERMISSION
	permissionLever: number
	icon: JSX.Element
	children?: CATEGORY[]
}

export default function LeftMenu() {
	const location = useLocation()
	const dispatch = useAppDispatch()
	const tokenInfo = useAppSelector((s) => s.UI.tokenInfo)
	const webs = useAppSelector((s) => s.CMS.Web.list)
	const webStatus = useAppSelector((s) => s.CMS.Web.status)
	const pageStatus = useAppSelector((s) => s.CMS.Page.status)
	const templateStatus = useAppSelector((s) => s.CMS.Template.status)
	const themeStatus = useAppSelector((s) => s.CMS.Theme.status)
	const webAuthorStatus = useAppSelector((s) => s.WebAuthor.status)
	const workflowStatus = useAppSelector((s) => s.Workflow.status)
	const formStatus = useAppSelector((s) => s.Form.status)
	const localStatus = useAppSelector((s) => s.FileLocal.status)
	const groupStatus = useAppSelector((s) => s.Group.status)
	useEffect(() => {
		if (tokenInfo && groupStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 1) dispatch(getGroups())
	}, [groupStatus, dispatch, tokenInfo])
	useEffect(() => {
		if (tokenInfo && webStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 1) {
			dispatch(getCommonCss())
			dispatch(getWebs())
		}
	}, [webStatus, dispatch, tokenInfo])
	useEffect(() => {
		if (tokenInfo && pageStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 1) dispatch(getPages())
	}, [pageStatus, dispatch, tokenInfo])
	useEffect(() => {
		if (tokenInfo && templateStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 1) dispatch(getTemplates())
	}, [templateStatus, dispatch, tokenInfo])
	useEffect(() => {
		if (tokenInfo && themeStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 1) dispatch(getThemes())
	}, [themeStatus, dispatch, tokenInfo])
	useEffect(() => {
		if (tokenInfo && webAuthorStatus === 'init') dispatch(getWebAuthors())
	}, [dispatch, tokenInfo, webAuthorStatus])
	useEffect(() => {
		if (tokenInfo && localStatus === 'init' && tokenInfo.permissions[PERMISSION.CMS] >= 4) dispatch(getFileLocals())
	}, [dispatch, tokenInfo, localStatus])
	useEffect(() => {
		if (tokenInfo && workflowStatus === 'init') {
			dispatch(getWorkflows())
		}
	}, [dispatch, tokenInfo, workflowStatus])
	useEffect(() => {
		if (tokenInfo && formStatus === 'init') {
			dispatch(getForms())
		}
	}, [dispatch, tokenInfo, formStatus])

	const categories = useMemo(() => {
		const cat: CATEGORY[] = [
			{
				text: 'お知らせ',
				href: '/',
				icon: <MdDashboard style={{ fontSize: 28 }} />,
				permissionLever: 1,
			},
			{
				text: '組織',
				href: '/group',
				icon: <VscOrganization style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.ACCOUNT,
				permissionLever: 1,
				children: [
					{
						text: '組織作成',
						href: '/group/create',
						icon: <AiOutlineUsergroupAdd style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.ACCOUNT,
						permissionLever: 3,
					},
				],
			},
			{
				text: 'CMS',
				href: '/cms',
				icon: <FaFileContract style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.CMS,
				permissionLever: 1,
				children: [
					{
						text: 'テーマ',
						href: '/cms/theme',
						icon: <ImInsertTemplate style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.CONFIG,
						permissionLever: 7,
					},
					{
						text: 'テンプレート',
						href: '/cms/template',
						icon: <CgTemplate style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.CMS,
						permissionLever: 4,
					},
					{
						text: '画像&ファイル管理',
						href: '/cms/files',
						icon: <AiOutlineFile style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.CMS,
						permissionLever: 4,
					},
					{
						text: 'ウェブページ',
						href: '/cms/website',
						icon: <VscRootFolderOpened style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.CMS,
						permissionLever: 1,
						children: webs.map((web) => ({
							href: `/cms/${web.id}/page`,
							icon: <FaPager style={{ fontSize: 24 }} />,
							requirePermission: PERMISSION.CMS,
							permissionLever: 1,
							text: web.name,
						})),
					},
				],
			},
			{
				text: 'フォーム',
				href: '/form',
				icon: <FaWpforms style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.FORM,
				permissionLever: 2,
				children: [
					{
						text: 'フォーム情報定義',
						href: '/form/create',
						icon: <MdOutlinePostAdd style={{ fontSize: 24 }} />,
						requirePermission: PERMISSION.CMS,
						permissionLever: 2,
					},
				],
			},
			{
				text: 'フォーム回答',
				href: '/form-answer',
				icon: <MdQuestionAnswer style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.FORM_ANSWER,
				permissionLever: 1,
			},
			{
				text: 'メール送信',
				href: '/mail',
				icon: <AiOutlineMail style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.MAIL,
				permissionLever: 1,
			},
			{
				text: 'メーリングリスト',
				href: '/mailing',
				icon: <BiMailSend style={{ fontSize: 28 }} />,
				requirePermission: PERMISSION.MAIL_ADDRESS,
				permissionLever: 2,
				children: [
					{
						text: 'メールアドレス追加',
						href: '/mailing/create',
						icon: <RiMailAddLine style={{ fontSize: 20 }} />,
						requirePermission: PERMISSION.MAIL_ADDRESS,
						permissionLever: 2,
					},
				],
			},
		]
		return cat
	}, [webs])
	if (!tokenInfo) return null
	return (
		<Stack
			sx={{
				height: (theme) => `calc(100vh - ${theme.mixins.toolbar.height}px - 0px)`,
				boxShadow: '1px 0 2px rgba(0,0,0,0.1)',
				bgcolor: (theme) => theme.palette.primary.main,
				color: (theme) => theme.palette.primary.contrastText,
				overflow: 'auto',
			}}
		>
			{categories.map((m, i) => {
				const children = m.children
				if (m.requirePermission === undefined || tokenInfo.permissions[m.requirePermission] >= m.permissionLever)
					return (
						<Stack key={i}>
							<NavLink
								to={m.href}
								end
								className={({ isActive }) => {
									return isActive
										? 'nav nav--active'
										: m.href !== '/' && location.pathname.split('/').includes(m.href.split('/').pop() || '#')
										? 'nav nav__parent'
										: 'nav'
								}}
								title={m.text}
							>
								<Stack direction="row" alignItems="center" gap={0.5}>
									<Stack
										sx={{
											width: 32,
											height: 32,
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										{m.icon}
									</Stack>
									<Typography
										sx={{
											maxWidth: 140,
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
										}}
									>
										{m.text}
									</Typography>
								</Stack>
							</NavLink>
							{children && (
								<Stack>
									{children.map((m, i) => {
										const children = m.children
										if (
											m.requirePermission === undefined ||
											tokenInfo.permissions[m.requirePermission] >= m.permissionLever
										)
											return (
												<Stack key={i}>
													<NavLink
														to={m.href}
														className={({ isActive }) => {
															return isActive ? 'nav nav__child nav--active' : 'nav nav__child'
														}}
														title={m.text}
													>
														<Stack direction="row" alignItems="center" gap={0.5}>
															<Stack
																sx={{
																	width: 28,
																	height: 28,
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																{m.icon}
															</Stack>
															<Typography
																variant="body2"
																sx={{
																	maxWidth: 140,
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	whiteSpace: 'nowrap',
																}}
															>
																{m.text}
															</Typography>
														</Stack>
													</NavLink>
													{children && (
														<Stack pl={3}>
															{children.map((m, i) => {
																if (
																	m.requirePermission === undefined ||
																	tokenInfo.permissions[m.requirePermission] >= m.permissionLever
																)
																	return (
																		<Stack key={i}>
																			<NavLink
																				to={m.href}
																				className={({ isActive }) => {
																					return isActive ? 'nav nav__child nav--active' : 'nav nav__child'
																				}}
																				title={m.text}
																			>
																				<Stack direction="row" alignItems="center" gap={0.5}>
																					<Stack
																						sx={{
																							width: 28,
																							height: 28,
																							justifyContent: 'center',
																							alignItems: 'center',
																						}}
																					>
																						{m.icon}
																					</Stack>
																					<Typography
																						variant="body2"
																						sx={{
																							maxWidth: 100,
																							overflow: 'hidden',
																							textOverflow: 'ellipsis',
																							whiteSpace: 'nowrap',
																						}}
																					>
																						{m.text}
																					</Typography>
																				</Stack>
																			</NavLink>
																		</Stack>
																	)
																return null
															})}
														</Stack>
													)}
												</Stack>
											)
										return null
									})}
								</Stack>
							)}
						</Stack>
					)
				return null
			})}
			<Stack>
				{(tokenInfo.workflow || tokenInfo.permissions[PERMISSION.CONFIG] > 4) && (
					<NavLink
						to={'/workflow'}
						end
						className={({ isActive }) => {
							return isActive ? 'nav nav--active' : 'nav'
						}}
						title={'ワークフロー'}
					>
						<Stack direction="row" alignItems="center" gap={0.5} sx={{ color: '#AACD08' }}>
							<Stack
								sx={{
									width: 32,
									height: 32,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<FaNetworkWired style={{ fontSize: 24 }} />
							</Stack>
							<Typography
								sx={{
									maxWidth: 140,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									fontWeight: 'bold',
								}}
							>
								ワークフロー
							</Typography>
						</Stack>
					</NavLink>
				)}
				{tokenInfo.permissions[PERMISSION.CONFIG] > 1 && (
					<NavLink
						to={'/author'}
						end
						className={({ isActive }) => {
							return isActive ? 'nav nav--active' : 'nav'
						}}
						title={'承認者管理'}
					>
						<Stack direction="row" alignItems="center" gap={0.5} sx={{ color: '#FDD000' }}>
							<Stack
								sx={{
									width: 32,
									height: 32,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<VscWorkspaceTrusted style={{ fontSize: 24 }} />
							</Stack>
							<Typography
								sx={{
									maxWidth: 140,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									fontWeight: 'bold',
								}}
							>
								承認者管理
							</Typography>
						</Stack>
					</NavLink>
				)}
			</Stack>
			<Stack flex={1} justifyContent="flex-end" pb={1}>
				<Divider sx={{ background: '#888' }} />
				{tokenInfo.permissions[PERMISSION.CONFIG] > 1 && (
					<>
						<NavLink
							to={'/notice'}
							end
							className={({ isActive }) => {
								return isActive ? 'nav nav--active' : 'nav'
							}}
							title={'お知らせ送信'}
						>
							<Stack direction="row" alignItems="center" gap={0.5} sx={{ color: '#2fa' }}>
								<Stack
									sx={{
										width: 32,
										height: 32,
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<AiOutlineNotification style={{ fontSize: 24 }} />
								</Stack>
								<Typography
									sx={{
										maxWidth: 140,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									お知らせ送信
								</Typography>
							</Stack>
						</NavLink>
						<Divider sx={{ background: '#888' }} />
						<NavLink
							to={'/setting'}
							end
							className={({ isActive }) => {
								return isActive ? 'nav nav--active' : 'nav'
							}}
							title={'環境変数設定'}
						>
							<Stack direction="row" alignItems="center" gap={0.5} sx={{ color: '#EC0000' }}>
								<Stack
									sx={{
										width: 32,
										height: 32,
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<AiOutlineSetting style={{ fontSize: 28 }} />
								</Stack>
								<Typography
									sx={{
										maxWidth: 140,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										fontWeight: 'bold',
									}}
								>
									環境変数設定
								</Typography>
							</Stack>
						</NavLink>
					</>
				)}
				<Divider sx={{ background: '#888' }} />
			</Stack>
		</Stack>
	)
}
