import { ITheme } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const ThemeApi = {
	async getAll() {
		const {
			data: { themes },
		} = await axiosClient.get<{ themes: ITheme[] }>('/api/cms/theme')
		return themes
	},
	async getOne(id: number | string) {
		const {
			data: { theme },
		} = await axiosClient.get<{ theme: ITheme }>(`/api/cms/theme/${id}`)
		return theme
	},
	async create({ name }: { name: string }) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>(`/api/cms/theme/`, {
			name,
		})
		return data
	},
	async update({
		id,
		name,
		body,
		head,
		script,
		style,
	}: {
		id: number | string
		name?: string
		head?: string
		body?: string
		style: string
		script: string
	}) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>(`/api/cms/theme/${id}`, {
			name,
			body,
			head,
			script,
			style,
		})
		return data
	},
	async delete(id: number | string) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string }>(`/api/cms/theme/${id}`)
		return data
	},
}
