import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { WebApi } from 'API/CMS/web'
import { IWeb, Status } from 'interfaces'

const initialState: {
	list: IWeb[]
	status: Status
	selected?:IWeb
} = {
	list: [],
	status: 'init',
}

export const getWebs = createAsyncThunk('Web/getWebs', async () => {
	return await WebApi.getAll()
})
export const getWeb = createAsyncThunk('Web/getWeb', async (id: number) => {
	return await WebApi.getOne(id)
})

const WebSlice = createSlice({
	name: 'Web',
	initialState,
	reducers: {
		setSelected(state,{ payload }: PayloadAction<typeof initialState.selected>) {
			state.selected = payload
		},
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getWebs.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getWebs.fulfilled, (state, { payload }) => {
			if (payload) state.list = payload as any
			state.status = 'fulfilled'
		})
		builder.addCase(getWeb.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload as any)
			} else {
				state.list.push(payload as any)
			}
		})
	},
})

export const WebAction = WebSlice.actions

export const WebReducer = WebSlice.reducer
