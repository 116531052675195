import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GroupApi } from 'API/group'
import { IGroup, Status } from 'interfaces'

const initialState: {
	list: IGroup[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getGroups = createAsyncThunk('Group/getGroups', async () => {
	const groups = await GroupApi.getAll()
	return {
		groups,
	}
})
export const getGroup = createAsyncThunk('Group/getGroup', async (id: number) => {
	return await GroupApi.getOne(id)
})

const GroupSlice = createSlice({
	name: 'Group',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getGroups.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getGroups.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getGroups.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload.groups
			}
			state.status = 'fulfilled'
		})
		builder.addCase(getGroup.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.push(payload)
			}
		})
	},
})

export const GroupAction = GroupSlice.actions

export const GroupReducer = GroupSlice.reducer
