import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { formAnswerApi } from 'API/formAnswer'
import { IFormAnswer, Status } from 'interfaces'

const initialState: {
	list: IFormAnswer[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getFormAnswers = createAsyncThunk('FormAnswer/getFormAnswers', async () => {
	const formAnswers = await formAnswerApi.getAll()
	return {
		formAnswers,
	}
})
export const getFormAnswer = createAsyncThunk('FormAnswer/getFormAnswer', async (formId: number) => {
	return await formAnswerApi.getByFormId(formId)
})

const FormAnswerSlice = createSlice({
	name: 'FormAnswer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getFormAnswers.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getFormAnswers.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getFormAnswers.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload.formAnswers
			}
			state.status = 'fulfilled'
		})
		builder.addCase(getFormAnswer.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.formId === payload.formId)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.push(payload)
			}
		})
	},
})

export const FormAnswerAction = FormAnswerSlice.actions

export const FormAnswerReducer = FormAnswerSlice.reducer
