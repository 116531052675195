import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import * as queryString from 'querystring'

const axiosClient: AxiosInstance = Axios.create({
	headers: {
		'content-type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
	},
	paramsSerializer: (param) => queryString.stringify(param),
})
axiosClient.interceptors.request.use(
	async function (config: AxiosRequestConfig) {
		return {
			...config,
		}
	},
	function (error) {
		return Promise.reject(error)
	}
)
let isShowConfirm = false
axiosClient.interceptors.response.use(
	function (response) {
		if (response.data.message === '権限なし' && !isShowConfirm) {
			isShowConfirm = true
			const confirm = window.confirm('権限が足りない為、再読み込みしますか')
			if (confirm) {
				isShowConfirm = true
				window.location.reload()
			} else {
				return
			}
		}
		return response
	},
	function (error: AxiosError) {
		return Promise.reject(
			error.response
				? {
						...error.response,
				  }
				: {
						status: 0,
						data: undefined,
				  }
		)
	}
)
export default axiosClient