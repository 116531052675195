import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import { TokenInfo } from 'interfaces'
import { useMemo, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosClient from 'utils/axiosClient'
import { UIAction } from './slices/UI'
import type { AppDispatch, RootState } from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useQuery() {
	const { search } = useLocation()

	return useMemo(() => new URLSearchParams(search), [search])
}

const loginRequest = {
	scopes: ['User.ReadWrite'],
}

export function useAuth() {
	const nav = useNavigate()
	const dispatch = useAppDispatch()
	const [loading, setLoading] = useState(false)
	const getAuth = async () => {
		try {
			const {
				data: { tokenInfo, error },
			} = await axiosClient.get<{
				tokenInfo?: TokenInfo
				error?: boolean
			}>('/auth')
			if (tokenInfo && !error) {
				dispatch(UIAction.setTokenInfo(tokenInfo))
				localStorage.setItem('auth_time', new Date().getTime().toString())
			} else dispatch(UIAction.setTokenInfo(undefined))
		} catch {
			dispatch(UIAction.setTokenInfo(undefined))
			// ---
		} finally {
			dispatch(UIAction.setLoginStatus('finished'))
		}
	}
	const signout = async () => {
		try {
			localStorage.clear()
			sessionStorage.clear()
			await axiosClient.post('/auth/logout')
			dispatch(UIAction.setTokenInfo(undefined))
		} catch {
			// ---
		}
	}
	const onLoginWithMsal = ({ clientId, tenantId }: { clientId: string; tenantId: string }) => {
		setLoading(true)
		const msalInstance = new PublicClientApplication({
			auth: {
				clientId: clientId,
				authority: 'https://login.microsoftonline.com/' + tenantId,
				knownAuthorities: ['https://login.microsoftonline.com/' + tenantId],
				redirectUri: window.location.origin,
				postLogoutRedirectUri: window.location.origin,
			},
			cache: {
				cacheLocation: 'localStorage',
			},
		})
		msalInstance.handleRedirectPromise().then(async (response: AuthenticationResult | null) => {
			let msalAccount: AccountInfo | null = null
			if (response && response.account) {
				msalAccount = response.account
			} else {
				const currentAccounts = msalInstance.getAllAccounts()
				if (currentAccounts.length === 0) {
					msalInstance.loginRedirect({
						...loginRequest,
						redirectUri: window.location.origin,
					})
				} else if (currentAccounts.length > 1) {
					msalAccount = currentAccounts[currentAccounts.length - 1]
				} else if (currentAccounts.length === 1) {
					msalAccount = currentAccounts[0]
				}
			}
			if (!msalAccount) return
			try {
				await axiosClient.get('/auth/login/msal')
				const {
					data: { tokenInfo },
				} = await axiosClient.post<{
					tokenInfo?: TokenInfo
					error?: boolean
					message?: string
				}>('/auth/login/msal', {
					name: msalAccount.name,
					email: msalAccount.username,
				})
				setLoading(false)
				if (tokenInfo) {
					dispatch(UIAction.setTokenInfo(tokenInfo))
					dispatch(UIAction.setLoginInfo(undefined))
					localStorage.setItem('auth_time', new Date().getTime().toString())
				}
			} catch (error) {
				setLoading(false)
			}
		})
	}
	const loginWithID = async (email: string, password: string) => {
		const {
			data: { otpSecret, otpauthUrl, require, error, message },
		} = await axiosClient.post<{
			error?: boolean
			message?: string
			otpSecret?: string
			otpauthUrl?: string
			require?: 'update_password' | 'verify_otp' | 'otp'
		}>('/auth/login', {
			email,
			password,
		})
		if (error && message) {
			dispatch(UIAction.addGlobalMessage({ message, type: 'error', time: 1000 * 20 }))
			return
		}
		if (require) {
			dispatch(
				UIAction.setLoginInfo({
					email,
					password,
					otpSecret,
					otpauthUrl,
				})
			)
			switch (require) {
				case 'update_password':
					nav('/login/update-password')
					break
				case 'verify_otp':
					nav('/login/verify-otp')
					break
				case 'otp':
					nav('/login/verify-token')
					break

				default:
					break
			}
		}
	}
	const verifyToken = async (email: string, password: string, otpSecret: string, token: string) => {
		const {
			data: { tokenInfo, message },
		} = await axiosClient.post<{
			error?: boolean
			message?: string
			tokenInfo: TokenInfo
		}>(`/auth/login/verify/${email}`, {
			password,
			otpSecret,
			token,
		})
		if (tokenInfo) {
			dispatch(UIAction.setTokenInfo(tokenInfo))
			dispatch(UIAction.setLoginInfo(undefined))
			localStorage.setItem('auth_time', new Date().getTime().toString())
		}
		if (message) {
			dispatch(
				UIAction.addGlobalMessage({
					message: `<p>${message} <br/><a href="/pw-update" style="color: #ffffff;
					font-weight: bold;
					text-decoration: underline;">こちら</a> からパスワードを変更してください。</p>`,
					type: 'info',
					time: 1000 * 30,
				})
			)
		}
	}
	const changePassword = async (email: string, password: string, newPassword: string) => {
		const {
			data: { error, message, otpSecret, otpauthUrl, require },
		} = await axiosClient.patch<{
			error?: boolean
			message?: string
			otpSecret?: string
			otpauthUrl?: string
			require?: 'update_password' | 'verify_otp' | 'otp'
		}>(`/auth/login/${email}`, {
			password,
			newPassword,
		})
		if (error && message) {
			dispatch(UIAction.addGlobalMessage({ message, type: 'error', time: 1000 * 20 }))
			return
		}
		if (require) {
			dispatch(
				UIAction.setLoginInfo({
					email,
					password: newPassword,
					otpSecret,
					otpauthUrl,
				})
			)
			switch (require) {
				case 'verify_otp':
					nav('/login/verify-otp')
					break
				case 'otp':
					nav('/login/verify-token')
					break

				default:
					break
			}
		}
	}

	return {
		getAuth,
		signout,
		onLoginWithMsal,
		loginWithID,
		verifyToken,
		changePassword,
		loading,
	}
}
