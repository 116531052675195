import { PERMISSION, SocketAction, SocketChanel, TokenInfo } from 'interfaces'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { PageAction, getPage, getPages } from 'redux/slices/CMS/Page'
import { TemplateAction, getTemplate, getTemplates } from 'redux/slices/CMS/Template'
import { ThemeAction, getTheme } from 'redux/slices/CMS/Theme'
import { WebAction, getWeb } from 'redux/slices/CMS/Web'
import { FileLocalAction, getFileLocal } from 'redux/slices/FileLocal'
import { FormAction, getForm, getForms } from 'redux/slices/Form'
import { getFormAnswers } from 'redux/slices/FormAnswer'
import { GroupAction, getGroup } from 'redux/slices/Group'
import { getMailHistories } from 'redux/slices/MailHistory'
import { MailingAction, getMailing } from 'redux/slices/Mailing'
import { NoticeAction, getNotice, getNotices } from 'redux/slices/Notice'
import { UIAction, getDomains, systemConfig } from 'redux/slices/UI'
import { WebAuthorAction, getWebAuthor } from 'redux/slices/WebAuthor'
import { WorkflowAction, getWorkflow, getWorkflows } from 'redux/slices/Workflow'
import { io } from 'socket.io-client'

const webPubSubEndpoint = 'https://caa-io.webpubsub.azure.com'
const wss = io(
	process.env.NODE_ENV === 'development' ? 'ws://' + window.location.hostname + ':488' : webPubSubEndpoint,
	{
		path: process.env.NODE_ENV === 'development' ? '/caa.io' : '/clients/socketio/hubs/caa',
		autoConnect: false,
	}
)
;(window as any).wss = wss
export default function SocketClient() {
	const tokenInfo = useAppSelector((s) => s.UI.tokenInfo)
	const dispatch = useAppDispatch()
	const location = useLocation()

	useEffect(() => {
		if (!tokenInfo) return
		if (!wss.connected) {
			wss.auth = tokenInfo
			wss.connect()
			wss.on('connect', () => {
				if (process.env.NODE_ENV === 'development') {
					console.group('--SOCKET--')
					console.log('Socket : ' + wss.id + ' is connected')
					console.groupEnd()
				}
			})
		}
		wss.onAny((chanel: SocketChanel, action: SocketAction, id = 0) => {
			if (process.env.NODE_ENV === 'development') {
				console.group('--SOCKET--')
				console.log({
					chanel,
					action,
					id,
				})
				console.groupEnd()
			}
			switch (chanel) {
				case 'account':
					if (Number(id) === tokenInfo.id) {
						window.location.reload()
						break
					}
					break
				case 'domain':
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getDomains())
							break
						case 'deleted':
							dispatch(UIAction.deleteDomain(id))
							break
						default:
							break
					}
					break
				case 'group':
					if (tokenInfo.permissions[PERMISSION.ACCOUNT] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getGroup(id))
							break
						case 'deleted':
							dispatch(GroupAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'cms-theme':
					if (tokenInfo.permissions[PERMISSION.CMS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getTheme(id))
							break
						case 'deleted':
							dispatch(ThemeAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'cms-template':
					if (tokenInfo.permissions[PERMISSION.CMS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getTemplate(id))
							break
						case 'deleted':
							dispatch(TemplateAction.deleteItem(id))
							break
						case 'async':
							dispatch(getTemplates())
							break
						default:
							break
					}
					break
				case 'cms-web':
					if (tokenInfo.permissions[PERMISSION.CMS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getWeb(id))
							break
						case 'deleted':
							dispatch(WebAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'cms-page':
					if (tokenInfo.permissions[PERMISSION.CMS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getPage(id))
							break
						case 'deleted':
							dispatch(PageAction.deleteItem(id))
							break
						case 'async':
							dispatch(getPages())
							break
						default:
							break
					}
					break
				case 'form':
					if (tokenInfo.permissions[PERMISSION.FORM] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getForm(id))
							break
						case 'deleted':
							dispatch(FormAction.deleteItem(id))
							break
						case 'async':
							dispatch(getForms())
							break
						default:
							break
					}
					break
				case 'local':
					if (tokenInfo.permissions[PERMISSION.CMS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getFileLocal(id))
							break
						case 'deleted':
							dispatch(FileLocalAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'mail-group':
					if (tokenInfo.permissions[PERMISSION.MAIL_ADDRESS] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getMailing(id))
							break
						case 'deleted':
							dispatch(MailingAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'author':
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getWebAuthor(id))
							break
						case 'deleted':
							dispatch(WebAuthorAction.deleteItem(id))
							break
						default:
							break
					}
					break
				case 'workflow':
					if (!tokenInfo.workflow && tokenInfo.permissions[PERMISSION.CONFIG] < 1) {
						break
					}
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getWorkflow(id))
							break
						case 'deleted':
							dispatch(WorkflowAction.deleteItem(id))
							break
						case 'async':
							dispatch(getWorkflows())
							break
						default:
							break
					}
					break
				case 'system-notice':
					switch (action) {
						case 'created':
						case 'updated':
							dispatch(getNotice(id))
							dispatch(
								UIAction.addGlobalMessage({
									type: 'info',
									message: '新着のお知らせがあります',
									time: 10000,
								})
							)
							break
						case 'deleted':
							dispatch(NoticeAction.deleteItem(id))
							break
						case 'async':
							dispatch(getNotices(tokenInfo?.groupId === 1 ? 100 : undefined))
							break
						default:
							break
					}
					break
				case 'mail-history':
					if (tokenInfo.permissions[PERMISSION.MAIL] < 1) {
						break
					}
					switch (action) {
						case 'async':
							dispatch(getMailHistories())
							break
						default:
							break
					}
					break
				case 'config':
					switch (action) {
						case 'async':
							dispatch(systemConfig())
							break
						default:
							break
					}
					break
				case 'answer':
					if (tokenInfo.permissions[PERMISSION.FORM_ANSWER] < 1) {
						break
					}
					switch (action) {
						case 'updated':
							dispatch(getFormAnswers())
							break
						case 'async':
							dispatch(getFormAnswers())
							break
						default:
							break
					}
					break
				case 'current-path':
					switch (action) {
						default:
							const currentPath = id as any as {
								wsId: string
								auth: TokenInfo
								path: string
								fifo: number
							}[]
							dispatch(UIAction.setCurrentPath(currentPath)) // id is currentPath object
							break
					}
					break
				default:
					break
			}
		})
	}, [dispatch, tokenInfo])

	useEffect(() => {
		if (tokenInfo && !location.state) {
			wss.emit('path', location.pathname)
		}
	}, [tokenInfo, location])

	return null
}
