import { IDomain } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const DomainAPI = {
	async getAll() {
		const {
			data: { domains },
		} = await axiosClient.get<{ domains: IDomain[] }>('/api/domain')
		return { domains }
	},
	async create(body: { name: string;displayName: string; clientId: string; tenantId: string }) {
		const {
			data,
		} = await axiosClient.post<{ error: boolean; message: string; id: number }>('/api/domain', body)
		return data
	},
	async update({
		id,
		...param
	}: {
		id: number
		name?: string
		clientId?: string
		displayName?: string
		tenantId?: string
	}) {
		const {
			data: { error, message },
		} = await axiosClient.put<{ error: boolean; message: string }>('/api/domain/' + id, param)
		return { error, message }
	},
	async delete(id: number | string) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>('/api/domain/' + id)
		return { error, message }
	},
}
