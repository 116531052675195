import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material/styles'
import { store } from 'redux/store'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.scss'

ReactDOM.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<Provider store={store}>
				<BrowserRouter>
					<CssBaseline />
					<App />
				</BrowserRouter>
			</Provider>
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
