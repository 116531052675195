import { Container, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
	const [counter, setCounter] = useState(5)
	const timer = useRef<NodeJS.Timer>()
	const nav = useNavigate()
	useEffect(() => {
		timer.current = setInterval(() => {
			setCounter((v) => {
				if (v === 1) clearInterval(timer.current)
				return v - 1
			})
		}, 1000)

		return () => {
			clearInterval(timer.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (counter === 0) {
			nav('/')
		}
	}, [counter, nav])

	return (
		<Container
			maxWidth="md"
			sx={{
				height: '100%',
				py: 4,
			}}
		>
			<Stack
				sx={{
					justifyContent: 'center',
					minHeight: '50%',
					p: 4,
					background: 'url(/404.svg) #eee8',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'top right',
					backgroundSize: '400px 400px',
				}}
				gap={2}
			>
				<Typography variant="h1" color="#a0a" fontWeight={700} sx={{ letterSpacing: '20px' }}>
					404
				</Typography>
				<Typography variant="h6" color="#f90">
					The page you’re looking for doesn’t exist.
				</Typography>
				<Typography variant="body2" color="#450">
					<Typography component='span' variant="h6">{counter}</Typography> 秒後にホームページへ移動します。
				</Typography>
			</Stack>
		</Container>
	)
}
