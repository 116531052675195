import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DomainAPI } from 'API/domain'
import { GlobalMessage, IDomain, TokenInfo } from 'interfaces'
import axiosClient from 'utils/axiosClient'

const initialState: {
	loginStatus: 'initial' | 'finished'
	tokenInfo?: TokenInfo
	loginInfo?: {
		email: string
		password: string
		otpSecret?: string
		otpauthUrl?: string
	}
	config: {
		[key: string]: string
	}
	allowDomains: IDomain[]
	globalMessages: GlobalMessage[]
	editable?: string
	currentPath: {
		wsId: string
		auth: TokenInfo
		path: string
		fifo: number
	}[]
	commonCss: string
} = {
	loginStatus: 'initial',
	config: {},
	globalMessages: [],
	currentPath: [],
	allowDomains: [],
	commonCss: '',
}

export const getDomains = createAsyncThunk('UI/getDomains', async () => {
	return await DomainAPI.getAll()
})
export const getCommonCss = createAsyncThunk('UI/getCommonCss', async () => {
	const { data } = await axiosClient.get('/common.css')
	return data
})

export const systemConfig = createAsyncThunk('UI/systemConfig', async () => {
	const {
		data: { config },
	} = await axiosClient.get<{
		config: {
			[key: string]: string
		}
	}>('/api/config')
	return { config }
})
const UISlice = createSlice({
	name: 'UI',
	initialState,
	reducers: {
		setEditable(state, { payload }: PayloadAction<typeof initialState.editable>) {
			state.editable = payload
		},
		setLoginStatus(state, { payload }: PayloadAction<typeof initialState.loginStatus>) {
			state.loginStatus = payload
		},
		setTokenInfo(state, { payload }: PayloadAction<typeof initialState.tokenInfo>) {
			state.tokenInfo = payload
		},
		setLoginInfo(state, { payload }: PayloadAction<typeof initialState.loginInfo>) {
			state.loginInfo = payload
		},
		setCurrentPath(state, { payload }: PayloadAction<typeof initialState.currentPath>) {
			state.currentPath = payload
		},
		addGlobalMessage(state, { payload }: PayloadAction<Omit<GlobalMessage, 'id'>>) {
			const id = new Date().getTime()
			state.globalMessages.push({ ...payload, id })
		},
		removeGlobalMessage(state, { payload }: PayloadAction<number>) {
			const index = state.globalMessages.findIndex((f) => f.id === Number(payload))
			if (index >= 0) {
				state.globalMessages.splice(index, 1)
			}
		},
		deleteDomain(state, { payload }: PayloadAction<number>) {
			if (state.allowDomains) {
				const index = state.allowDomains.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.allowDomains.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(systemConfig.fulfilled, (state, { payload: { config } }) => {
			state.config = config
		})
		builder.addCase(getDomains.fulfilled, (state, { payload }) => {
			if (payload) state.allowDomains = payload.domains
		})
		builder.addCase(getCommonCss.fulfilled, (state, { payload }) => {
			if (payload) state.commonCss = payload
		})
	},
})

export const UIAction = UISlice.actions

export const UIReducer = UISlice.reducer
