import CloseIcon from '@mui/icons-material/Close'
import { alpha, Slide, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { GlobalMessage } from 'interfaces'
import { useState, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { UIAction } from 'redux/slices/UI'

export default function Alerts() {
	const globalMessages = useAppSelector((s) => s.UI.globalMessages)

	return (
		<Box
			sx={{
				position: 'fixed',
				pt: 1,
				top: (theme) => theme.mixins.toolbar.height,
				right: 5,
				zIndex: 99999,
			}}
		>
			<Box maxWidth={'sm'}>
				{globalMessages.map((message) => (
					<Toast message={message} key={message.id} />
				))}
			</Box>
		</Box>
	)
}

interface Props {
	message: GlobalMessage
}

function Toast({ message: { id, message, type, time = 6000 } }: Props) {
	const [open, setOpen] = useState(true)
	const timer = useRef<NodeJS.Timeout>()
	const dispatch = useAppDispatch()
	useEffect(() => {
		timer.current = setTimeout(() => {
			setOpen(false)
			setTimeout(() => {
				dispatch(UIAction.removeGlobalMessage(id))
			}, 200)
		}, time)
		return () => {
			clearTimeout(timer.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Slide direction="left" in={open} mountOnEnter unmountOnExit>
			<Alert
				severity={type}
				variant="filled"
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						onClick={() => {
							setOpen(false)
							setTimeout(() => {
								dispatch(UIAction.removeGlobalMessage(id))
							}, 1000)
						}}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				}
				sx={{
					position: 'relative',
					mb: 1,
					p: 0,
					px: 1,
					display: 'flex',
					alignItems: 'center',
					overflow: 'hidden',
					boxShadow: (theme) => `0 0 20px ${alpha(theme.palette.text.primary, 0.3)}`,
					'& .MuiAlert-action': {
						p: 0,
					},
				}}
			>
				<Typography
					color="inherit"
					dangerouslySetInnerHTML={{
						__html: message,
					}}
					variant="body2"
					component="div"
				></Typography>
				<Box
					sx={{
						position: 'absolute',
						bottom: 1,
						left: 0,
						height: 4,
						width: '100%',
						bgcolor: (theme) => theme.palette[type].main,
						boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
						'&:after': {
							content: '""',
							position: 'absolute',
							bottom: 1,
							left: 0,
							height: 3,
							width: 0,
							bgcolor: (theme) => theme.palette[type].light,
							animation: `process-alert ${time}ms linear`,
						},
						'@keyframes process-alert': {
							'0%': {
								width: 0,
							},
							'100%': {
								width: '100%',
							},
						},
					}}
				></Box>
			</Alert>
		</Slide>
	)
}
