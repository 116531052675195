import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MailApi } from 'API/mailHistory'
import { ISendMailHistory, Status } from 'interfaces'

const initialState: {
	list: ISendMailHistory[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getMailHistories = createAsyncThunk('MailHistory/getMailHistories', async () => {
	const histories = await MailApi.getHistories()
	return {
		histories,
	}
})
const MailHistorySlice = createSlice({
	name: 'MailHistory',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getMailHistories.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getMailHistories.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getMailHistories.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload.histories
			}
			state.status = 'fulfilled'
		})
	},
})

export const MailHistoryAction = MailHistorySlice.actions

export const MailHistoryReducer = MailHistorySlice.reducer
