import { IPage, ValidateError } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const PageApi = {
	async getAll() {
		const {
			data: { pages },
		} = await axiosClient.get<{ pages: IPage[] }>('/api/cms/page')
		return pages
	},
	async getOne(id: number | string) {
		const {
			data: { page },
		} = await axiosClient.get<{ page: IPage }>(`/api/cms/page/${id}`)
		return page
	},
	async create(body: {
		webId: number
		themeId: number
		url: string
		title: string
		description?: string
		body?: { [key: string]: string }
		style?: string
		script?: string
		dateOfPage?: string
		statusOfPage?: string
	}) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>(`/api/cms/page/`, body)
		return data
	},
	async update({
		id,
		...body
	}: {
		id: number | string
		url?: string
		title?: string
		description?: string
		body?: { [key: string]: string }
		subOwner?: number[]
		isPublic?: boolean
		style?: string
		script?: string
		formStyle?: string
		formScript?: string
		dateOfPage?: string
		statusOfPage?: string
	}) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>(`/api/cms/page/${id}`, body)
		return data
	},
	async active(id: number | string) {
		const { data } = await axiosClient.patch<{ error: boolean; message: string }>(`/api/cms/page/${id}/active`)
		return data
	},
	async delete(id: number | string) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string }>(`/api/cms/page/${id}`)
		return data
	},
	async validate(html: string) {
		const { data } = await axiosClient.post<{
			errList: ValidateError[]
			error: boolean
			message: string
		}>(`/api/cms/validate/`, { html })
		return data
	},
	async checkUsed(id: number | string) {
		const { data } = await axiosClient.patch<{
			error: boolean
			message: string
			usedPages: string[]
		}>(`/api/cms/page/checkUsed/${id}`)
		return data
	},
}
