import { IWebAuthor } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const WebAuthorAPI = {
	async getAll() {
		const {
			data: { webAuthors, error },
		} = await axiosClient.get<{ webAuthors: IWebAuthor[]; error: boolean; message: string }>('/api/author')
		return error ? [] : webAuthors
	},
	async getOne(id: number | string) {
		const {
			data: { webAuthor, error },
		} = await axiosClient.get<{ webAuthor: IWebAuthor; error: boolean; message: string }>(`/api/author/${id}`)
		return error ? undefined : webAuthor
	},
	async create(body: { email: string; userIds: number[]; webIds: number[] }) {
		const { data } = await axiosClient.post<{ error: boolean; message: string; id: number }>('/api/author', body)
		return data
	},
	async update(body: { email?: string; userIds?: number[]; webIds?: number[] }) {
		const { data } = await axiosClient.put<{ error: boolean; message: string; }>('/api/author', body)
		return data
	},
	async delete(email: string) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string; }>('/api/author/' + email)
		return data
	},
}
