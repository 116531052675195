import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WebAuthorAPI } from 'API/author'
import { IWebAuthor, Status } from 'interfaces'

const initialState: {
	list: IWebAuthor[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getWebAuthors = createAsyncThunk('WebAuthor/getWebAuthors', async () => {
	const data = await WebAuthorAPI.getAll()
	return data
})
export const getWebAuthor = createAsyncThunk('WebAuthor/getWebAuthor', async (id: number) => {
	return await WebAuthorAPI.getOne(id)
})

const WebAuthorSlice = createSlice({
	name: 'WebAuthor',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getWebAuthors.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getWebAuthors.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getWebAuthors.fulfilled, (state, { payload }) => {
			if (payload) {
				state.list = payload
			}
			state.status = 'fulfilled'
		})
		builder.addCase(getWebAuthor.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.push(payload)
			}
		})
	},
})

export const WebAuthorAction = WebAuthorSlice.actions

export const WebAuthorReducer = WebAuthorSlice.reducer
