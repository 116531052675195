import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TemplateApi } from 'API/CMS/template'
import { ITemplate, Status } from 'interfaces'

const initialState: {
	list: ITemplate[]
	status: Status
	selected?:ITemplate
} = {
	list: [],
	status: 'init',
}

export const getTemplates = createAsyncThunk('Template/getTemplates', async () => {
	return await TemplateApi.getAll()
})
export const getTemplate = createAsyncThunk('Template/getTemplate', async (id: number) => {
	return await TemplateApi.getOne(id)
})

const TemplateSlice = createSlice({
	name: 'Template',
	initialState,
	reducers: {
		setSelected(state,{ payload }: PayloadAction<typeof initialState.selected>) {
			state.selected = payload
		},
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTemplates.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getTemplates.fulfilled, (state, { payload }) => {
			if (payload) state.list = payload as any
			state.status = 'fulfilled'
		})
		builder.addCase(getTemplate.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload as any)
			} else {
				state.list.push(payload as any)
			}
		})
	},
})

export const TemplateAction = TemplateSlice.actions

export const TemplateReducer = TemplateSlice.reducer
