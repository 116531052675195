import { IWorkflow } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const WorkflowAPI = {
	async getAll() {
		const {
			data: { workflows, error },
		} = await axiosClient.get<{ workflows: IWorkflow[]; error: boolean; message: string }>('/api/workflow')
		return error ? [] : workflows
	},
	async getOne(id: number | string) {
		const {
			data: { workflow, error },
		} = await axiosClient.get<{ workflow: IWorkflow; error: boolean; message: string }>(`/api/workflow/${id}`)
		return error ? undefined : workflow
	},
	async create(body: {
		authorizers: number[]
		pageIds: {
			public: number[]
			unPublic: number[]
		}
		hopeTime?: number
	}) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/workflow', body)
		return data
	},
	async emergency(body: {
		authorizers: number[]
		pageIds: {
			public: number[]
			unPublic: number[]
		}
	}) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/workflow/emergency', body)
		return data
	},
	async rePublic(webId: number) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/workflow/rePublic', { webId })
		return data
	},
	async remand(body: { id: number }) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>('/api/workflow/remand', body)
		return data
	},
	async approval(body: { id: number }) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>('/api/workflow/approval', body)
		return data
	},
	async reject(body: { id: number; other: string }) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>('/api/workflow/reject', body)
		return data
	},
}
