import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LOCAL_API } from 'API/local'
import { IFileLocal, Status } from 'interfaces'

const initialState: {
	images: IFileLocal[]
	docs: IFileLocal[]
	status: Status
} = {
	images: [],
	docs: [],
	status: 'init',
}

export const getFileLocals = createAsyncThunk('FileLocal/getFileLocals', async () => {
	return await LOCAL_API.getAll()
})
export const getFileLocal = createAsyncThunk('FileLocal/getFileLocal', async (id: number | string) => {
	return await LOCAL_API.getOne(id)
})

const FileLocalSlice = createSlice({
	name: 'FileLocal',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			const iId = state.images.findIndex((f) => f.id === payload)
			if (iId >= 0) {
				state.images.splice(iId, 1)
			}
			const fId = state.docs.findIndex((f) => f.id === payload)
			if (fId >= 0) {
				state.docs.splice(fId, 1)
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getFileLocals.pending, (state) => {
			state.status = 'pending'
		})
		builder.addCase(getFileLocals.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getFileLocals.fulfilled, (state, { payload }) => {
			state.status = 'fulfilled'
			state.images = payload.images
			state.docs = payload.docs
		})
		builder.addCase(getFileLocal.fulfilled, (state, { payload }) => {
			if (payload.type === 'imgs') {
				const index = state.images.findIndex((f) => f.id === payload.id)
				if (index >= 0) {
					state.images.splice(index, 1, payload)
				} else {
					state.images.push(payload)
				}
			} else {
				const index = state.docs.findIndex((f) => f.id === payload.id)
				if (index >= 0) {
					state.docs.splice(index, 1, payload)
				} else {
					state.docs.push(payload)
				}
			}
		})
	},
})

export const FileLocalAction = FileLocalSlice.actions

export const FileLocalReducer = FileLocalSlice.reducer
