import { INotice } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const noticeAPI = {
	async getAll(max?: number) {
		const {
			data: { notices, error },
		} = await axiosClient.get<{ notices: INotice[]; error: boolean; message: string }>(`/api/notice?max=${max || ''}`)
		return error ? [] : notices
	},
	async getOne(id: number | string) {
		const {
			data: { notice, error },
		} = await axiosClient.get<{ notice: INotice; error: boolean; message: string }>(`/api/notice/${id}`)
		return error ? undefined : notice
	},
	async create(body: {
		type?: 'エラー' | '成功' | '警告' | 'お知らせ' | '緊急'
		groupId?: number[]
		important?: boolean
		content: string
	}) {
		const { data } = await axiosClient.post<{ error: boolean; message: string; id: number }>('/api/notice', body)
		return data
	},
	async update({
		id,
		...param
	}: {
		id: number
		type?: 'エラー' | '成功' | '警告' | 'お知らせ' | '緊急'
		groupId?: number[]
		important?: boolean
		content?: string
	}) {
		const { data } = await axiosClient.put<{ error: boolean; message: string }>(`/api/notice/${id}`, param)
		return data
	},
	async delete(id: number) {
		const { data } = await axiosClient.delete<{ error: boolean; message: string }>(`/api/notice/${id}`)
		return data
	},
}
