import { IMailing } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const MailingApi = {
	async getAll() {
		const {
			data: { mailings, error },
		} = await axiosClient.get<{ mailings: IMailing[]; error: boolean; message: string }>('/api/mailing')
		return error ? [] : mailings
	},
	async getOne(id: number | string) {
		const {
			data: { mailing, error },
		} = await axiosClient.get<{ mailing: IMailing; error: boolean; message: string }>(`/api/mailing/${id}`)
		return error ? undefined : mailing
	},
	async create({ name, type }: { name: string; type: string }) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>('/api/mailing/', {
			name,
			type,
		})
		return data
	},
	async addMember({ id, email }: { id: string | number; email: string }) {
		const { data } = await axiosClient.post<{ id: number; error: boolean; message: string }>(
			`/api/mailing/${id}/member`,
			{
				email,
			}
		)
		return data
	},
	async removeMember({ id, memberId }: { id: string | number; memberId: string | number }) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>(`/api/mailing/${id}/${memberId}`)
		return { error, message }
	},
	async delete(id: number, force?: boolean) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>(`/api/mailing/${id}${force ? '?force=true' : ''}`)
		return { error, message }
	},
	async update({ id, name, allowGroup }: { id: number | string; name?: string; allowGroup?: number[] }) {
		const {
			data: { error, message },
		} = await axiosClient.put<{ error: boolean; message: string }>('/api/mailing/' + id, {
			name,
			allowGroup,
		})
		return { error, message }
	},
}
