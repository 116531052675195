import { IFileLocal } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const LOCAL_API = {
	async getAll() {
		const {
			data: { images, docs },
		} = await axiosClient.get('/api/local')
		return { images, docs }
	},
	async getOne(id: number | string) {
		const {
			data: { file },
		} = await axiosClient.get<{
			file: IFileLocal
		}>(`/api/local/${id}`)
		return file
	},
	async upload({ formData }: { formData: FormData }) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/local/upload', formData)
		return data
	},
	async delete(id: number | string) {
		const {
			data: { error, message },
		} = await axiosClient.delete<{ error: boolean; message: string }>('/api/local/' + id)
		return { error, message }
	},
}
