import NewReleasesIcon from '@mui/icons-material/NewReleases'
import {
	Badge,
	Box,
	ClickAwayListener,
	IconButton,
	Stack,
	Tooltip,
	TooltipProps,
	Typography,
	styled,
	tooltipClasses,
} from '@mui/material'
import { INotice } from 'interfaces'
import { useEffect, useState } from 'react'
import { MdNotifications } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { NoticeAction, getNotices } from 'redux/slices/Notice'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#fff',
		color: 'currentColor',
		maxWidth: 'none',
		width: '800px !important',
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #dadde9',
		boxShadow: '-2px 2px 4px rgba(0,0,0,0.1),-2px 2px 10px rgba(0,0,0,0.1)',
	},
}))

export default function Notice() {
	const dispatch = useAppDispatch()
	const tokenInfo = useAppSelector((s) => s.UI.tokenInfo)
	const [open, setOpen] = useState<boolean>()
	const status = useAppSelector((s) => s.Notice.status)
	const notices = useAppSelector((s) => s.Notice.list)
	const unread = useAppSelector((s) => s.Notice.unread)
	useEffect(() => {
		if (status === 'init') dispatch(getNotices(tokenInfo?.groupId === 1 ? 100 : undefined))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (!tokenInfo) return
		const localUnread = localStorage.getItem(tokenInfo.email)
		const localNotices: INotice[] | undefined = localUnread ? JSON.parse(localUnread) : undefined
		let localTmp: INotice[] = localNotices || []
		if (!localNotices) {
			localStorage.setItem(tokenInfo.email, '[]')
		}
		const tmp = notices.filter((f) => !localTmp.find((x) => x.id === f.id)) // unread item
		dispatch(
			NoticeAction.setUnread({
				key: tokenInfo.email,
				value: tmp,
			})
		)
	}, [dispatch, notices, tokenInfo])
	const onClose = () => {
		if (!tokenInfo || !open) return
		localStorage.setItem(tokenInfo.email, JSON.stringify(notices))
		dispatch(
			NoticeAction.setUnread({
				key: tokenInfo.email,
				value: [],
			})
		)
	}
	return (
		<ClickAwayListener
			onClickAway={() => {
				onClose()
				setOpen(false)
			}}
		>
			<Box>
				<HtmlTooltip
					open={open || false}
					arrow
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={
						<Stack
							sx={{
								overflowY: 'auto',
								maxHeight: '60vh',
								py: 2,
							}}
						>
							{notices.slice(0,20).map((notice) => {
								let bgcolor = '#2A71B9'
								let color = '#fff'
								switch (notice.type) {
									case 'エラー':
										bgcolor = '#EC0000'
										color = '#fff'
										break
									case '成功':
										bgcolor = '#388E3C'
										color = '#fff'
										break
									case '警告':
										bgcolor = '#FDD000'
										color = '#fff'
										break
									case '緊急':
										bgcolor = '#EC0000'
										color = '#fff'
										break

									default:
										break
								}
								return (
									<Stack key={notice.id}>
										<Stack
											direction="row"
											gap={1}
											alignItems="baseline"
											justifyContent="flex-start"
											sx={{
												position: 'relative',
												p: 0.5,
												borderBottom: '1px solid rgba(0,0,0,0.1)',
												bgcolor: unread.value.find((f) => f.id === notice.id) ? '#00f1' : '#fff',
											}}
										>
											<Typography
												variant="button"
												sx={{
													bgcolor,
													color,
													width: 100,
													textAlign: 'center',
													position: 'relative',
												}}
											>
												{notice.type}
											</Typography>
											<Box flex={1} component="div" dangerouslySetInnerHTML={{ __html: notice.content }} sx={{}}></Box>
											<Stack>
												{notice.important && (
													<NewReleasesIcon sx={{ color: (theme) => theme.palette.error.main, fontSize: 28 }} />
												)}
											</Stack>
										</Stack>
									</Stack>
								)
							})}
						</Stack>
					}
				>
					<IconButton
						onClick={() => {
							if (open) {
								onClose()
							}
							setOpen(!open)
						}}
					>
						<Badge badgeContent={unread.value.length} color="secondary">
							<MdNotifications fontSize={28} color="#fff" />
						</Badge>
					</IconButton>
				</HtmlTooltip>
			</Box>
		</ClickAwayListener>
	)
}
