import { IFormAnswer } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const formAnswerApi = {
	async getAll() {
		const {
			data: { formAnswers, error },
		} = await axiosClient.get<{ formAnswers: IFormAnswer[]; error: boolean; message: string }>('/api/form-answer')
		return error ? [] : formAnswers
	},
	async getByFormId(formId: number) {
		const {
			data: { formAnswer, error },
		} = await axiosClient.get<{ formAnswer: IFormAnswer; error: boolean; message: string }>(
			`/api/form-answer/${formId}`
		)
		return error ? undefined : formAnswer
	},
	async deleteRows(body: { ids: number[] }) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/form-answer/remove-row', body)
		return data
	},
	async downloaded(answers: number[]) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/form-answer/downloaded', {
			answers,
		})
		return data
	},
	async downloadCsv(datas: string, answers: number[]) {
		const { data } = await axiosClient.post<{ error: boolean; message: string; url: string }>(
			'/api/form-answer/downloadZip',
			{
				datas,
				answers,
			}
		)
		return data.url
	},
}
