import { ISendMailHistory } from 'interfaces'
import axiosClient from 'utils/axiosClient'

export const MailApi = {
	async getHistories() {
		const {
			data: { histories, error },
		} = await axiosClient.get<{ histories: ISendMailHistory[]; error: boolean; message: string }>('/api/mail/history')
		return error ? [] : histories
	},
	async send(body: { mailingIds: number[]; subject: string; html: string; hopeTime?: number }) {
		const { data } = await axiosClient.post<{ error: boolean; message: string }>('/api/mail/send', body)
		return data
	},
}
