import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { UIReducer as UI } from './slices/UI'
import { GroupReducer as Group } from './slices/Group'
import { MailingReducer as Mailing } from './slices/Mailing'
import { MailHistoryReducer as MailHistory } from './slices/MailHistory'
import { FormReducer as Form } from './slices/Form'
import { FileLocalReducer as FileLocal } from './slices/FileLocal'
import { ThemeReducer as Theme } from './slices/CMS/Theme'
import { TemplateReducer as Template } from './slices/CMS/Template'
import { PageReducer as Page } from './slices/CMS/Page'
import { WebReducer as Web } from './slices/CMS/Web'
import { WorkflowReducer as Workflow } from './slices/Workflow'
import { WebAuthorReducer as WebAuthor } from './slices/WebAuthor'
import { FormAnswerReducer as FormAnswer } from './slices/FormAnswer'
import { NoticeReducer as Notice } from './slices/Notice'

export const rootReducer = combineReducers({
	UI,
	Group,
	CMS: combineReducers({
		Theme,
		Template,
		Page,
		Web,
	}),
	Workflow,
	WebAuthor,
	Mailing,
	MailHistory,
	Form,
	FormAnswer,
	FileLocal,
	Notice,
})

export const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
