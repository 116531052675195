import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ThemeApi } from 'API/CMS/theme'
import { ITheme, Status } from 'interfaces'

const initialState: {
	list: ITheme[]
	status: Status
} = {
	list: [],
	status: 'init',
}

export const getThemes = createAsyncThunk('Theme/getThemes', async () => {
	return await ThemeApi.getAll()
})
export const getTheme = createAsyncThunk('Theme/getTheme', async (id: number) => {
	return await ThemeApi.getOne(id)
})

const ThemeSlice = createSlice({
	name: 'Theme',
	initialState,
	reducers: {
		deleteItem(state, { payload }: PayloadAction<number>) {
			if (state.list) {
				const index = state.list.findIndex((f) => f.id === payload)
				if (index >= 0) {
					state.list.splice(index, 1)
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getThemes.rejected, (state) => {
			state.status = 'rejected'
		})
		builder.addCase(getThemes.fulfilled, (state, { payload }) => {
			if (payload) state.list = payload
			state.status = 'fulfilled'
		})
		builder.addCase(getTheme.fulfilled, (state, { payload }) => {
			if (!payload) return
			const index = state.list.findIndex((f) => f.id === payload.id)
			if (index >= 0) {
				state.list.splice(index, 1, payload)
			} else {
				state.list.push(payload)
			}
		})
	},
})

export const ThemeAction = ThemeSlice.actions

export const ThemeReducer = ThemeSlice.reducer
